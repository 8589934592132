import React from 'react'
import styles from './SupportSection.module.scss'
import PictureImgix from '@/components/PictureImgix/PictureImgix'
import useDeviceType from '@/hooks/useDeviceType'
import { open as OpenChat } from '@saatva-bits/pattern-library.utils.chat'
import { phoneNumbers } from '@/constants/contact-info'

const SupportSection = () => {
    const { isMobile, isTablet } = useDeviceType('mobile')
    const chatMessage = isTablet ? 'Chat' : 'Chat with us'

    const images = [
        { name: 'lifestyle-bedroom-left-1-1.jpg', alt: 'Pillow Loft Comparison' },
        { name: 'lifestyle-bedroom-right-1-1.jpg', alt: 'Pillow Loft Comparison' },
    ]

    return (
        <div className={styles.supportSection}>
            <div className={styles.contentWrapper}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Questions?</h2>
                    <p className={styles.subtitle}>We're always here to help.</p>
                    <p className={styles.contactInfo}>
                        <a className={styles.chatLink} href={`tel:${phoneNumbers.supportPhoneNumber}`}>Call {phoneNumbers.supportPhoneNumber}</a>
                        <span className={styles.dot}>•</span>
                        <a className={styles.chatLink} onClick={OpenChat}>{chatMessage}</a>
                    </p>
                </div>
                {!isMobile && (
                    images.map((image) => (
                        <div key={image.name} className={styles.container}>
                            <PictureImgix
                                key={image.name}
                                folder='generic/lifestyle-bedroom'
                                name={image.name}
                                alt={image.alt}
                                prefixOverride={{ mobile: 'none', tablet: 'none', desktop: 'none' }}
                                widths={{ mobile: 350, tablet: 210, desktop: 348 }}
                                imgixDomain={process.env.NEXT_PUBLIC_IMGIX_URL}
                                isUniversal={false}
                                usePrefix={false}
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default SupportSection
